<template>
  <div ref="base" class="relative bg-gray-100 rounded-full px-1 gap-1 py-1 overflow-hidden inline-flex">
    <div
      ref="toogle"
      class="absolute transition-all duration-75 top-1 bottom-1 rounded-full"
      :style="{ width: widthPos + 'px', left: leftPos + 'px' }"
      :class="{
        'bg-primary border-primary text-white': !disabled,
        'border-gray-400 bg-gray-400': disabled,
        'cursor-not-allowed': disabled,
      }"
    ></div>
    <button
      v-for="(option, o) in options"
      :ref="'option_' + o"
      :key="'option_' + o"
      :disabled="disabled"
      class="text-xs font-semibold transition-all px-2.5 py-1.5 rounded-full"
      :class="{
        'text-white': selectedIndex === o && !disabled,
        'cursor-not-allowed': disabled,
        'bg-gray-500': offAsGray && o && selectedIndex === o && !disabled,
      }"
      style="z-index: 1"
      @click="selectOption(o)"
    >
      {{ option }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'OnoffOption',
  props: {
    options: {
      default() {
        return []
      },
      required: true,
      type: Array,
    },
    selectedOption: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    offAsGray: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      leftPos: -5,
      widthPos: 0,
      selectedIndex: -1,
    }
  },
  watch: {
    selectedOption(newVal) {
      if (newVal) this.selectOption(this.options.indexOf(newVal), false)
    },
  },
  mounted() {
    if (this.selectedOption) {
      this.selectOption(this.options.indexOf(this.selectedOption), false)
    }
  },
  methods: {
    selectOption(option, emit = true) {
      if (this.selectedIndex === option) {
        /*
        TOOGLE DEACTIVATED! problems with reactivity
        this.widthPos = 0
        this.leftPos = -5
        this.selectedIndex = -1
        if (emit) this.$emit('input', null)*/
      } else {
        this.selectedIndex = option
        let elem = this.$refs['option_' + option][0]
        let baseelem = this.$refs['base']
        this.widthPos = elem.getBoundingClientRect().width
        this.leftPos = elem.getBoundingClientRect().left - baseelem.getBoundingClientRect().left
        if (emit) this.$emit('input', option)
      }
    },
  },
}
</script>

<style>
.green-outline-focus {
  outline: solid 2px rgba(32, 164, 149, 0);
}
.green-outline-focus:focus {
  outline: solid 2px rgba(32, 164, 149, 0.5);
}
.green-outline-focus:active {
  outline: solid 2px rgba(32, 164, 149, 0.5);
}
</style>
