var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"progress-icon flex gap-x-2 items-center",class:{ 'flex-col': _vm.percentagePos === 'top' || _vm.percentagePos === 'bottom' }},[(_vm.showPercentage && (_vm.percentagePos === 'top' || _vm.percentagePos === 'left'))?_c('div',{staticClass:"w-full font-semibold rounded-full",class:{
      'text-right': _vm.align === 'right',
      'text-left': _vm.align === 'left',
      'text-white': _vm.type == 'light',
      'text-gray-600': _vm.type == 'regular',
    }},[_vm._v(" "+_vm._s(_vm.progress)+"% ")]):_vm._e(),_c('div',{staticClass:"h-4 rounded-full flex-1 bg-opacity-20 w-full flex-shrink-0 overflow-hidden",class:{
      'text-white bg-white bg-opacity-10': _vm.type == 'light',
      'text-gray-600 bg-gray-500 bg-opacity-20': _vm.type == 'regular',
    }},[_c('div',{staticClass:"h-4 rounded-full transition-all",class:[`bg-${_vm.barColor}`],style:({ width: _vm.progress + '%' })})]),(_vm.showPercentage && (_vm.percentagePos === 'bottom' || _vm.percentagePos === 'right'))?_c('div',{staticClass:"text-sm font-semibold",class:{
      'text-right': _vm.align === 'right',
      'text-left': _vm.align === 'left',
      'w-full': _vm.percentagePos === 'bottom',
      'text-white opacity-80 font-medium': _vm.type == 'light',
      'text-gray-500': _vm.type == 'regular',
    }},[_vm._v(" "+_vm._s(_vm.progress)+"% ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }