<template>
  <div>
    <div class="flex items-center rounded-md bg-gray-100">
      <div
        v-if="hasPrefix"
        class="rounded-l-lg h-full px-2.5 py-2 text-gray-500 border shadow-sm border-gray-200 bg-gray-200 bg-opacity-40"
      >
        <slot name="prefix"></slot>
      </div>
      <div class="flex-1">
        <div v-if="hasMain" class="pr-1">
          <slot name="main"></slot>
        </div>
        <input
          v-else
          :id="id"
          :min="min"
          :max="max"
          :step="step"
          type="number"
          :value="value"
          :disabled="disabled"
          class="input w-full px-2.5 py-1"
          :class="[
            {
              'rounded-none': hasPrefix || hasSuffix,
              'rounded-r-md': hasPrefix && !hasSuffix,
              'rounded-l-md': hasSuffix && !hasPrefix,
              'rounded-md': !hasPrefix && !hasSuffix,
            },
            customClass,
          ]"
          :placeholder="placeholder"
          :name="id"
          @input="emitInput($event.target.value)"
          @change="emitChange($event.target.value)"
          @focus="emitFocus()"
          @blur="emitBlur()"
        />
      </div>
      <div
        v-if="hasSuffix"
        class="rounded-r-lg h-full px-2.5 py-2 text-gray-500 border shadow-sm border-gray-200 bg-gray-200 bg-opacity-40"
      >
        <slot name="suffix"></slot>
      </div>
    </div>
    <div v-if="help" class="text-xs text-gray-400 py-1">
      {{ help }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'NumberInput',
  props: {
    value: {
      type: Number,
    },
    step: {
      type: Number,
    },
    placeholder: {
      type: String,
    },
    id: {
      type: String,
    },
    help: {
      type: String,
    },
    inputClass: {
      type: String,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    customClass: {
      type: String,
      default: '',
    },
  }, //['value', 'placeholder', 'id'],
  computed: {
    hasPrefix() {
      return !!this.$slots['prefix']
    },
    hasSuffix() {
      return !!this.$slots['suffix']
    },
    hasMain() {
      return !!this.$slots['main']
    },
  },
  methods: {
    emitFocus() {
      this.$emit('focus')
    },
    emitBlur() {
      this.$emit('blur')
    },
    emitInput(event) {
      this.$emit('input', Number(event))
    },
    emitChange(event) {
      this.$emit('change', Number(event))
    },
  },
}
</script>

<style scoped>
/* Chrome, Safari, Edge, Opera
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
*/
/* Firefox
input[type='number'] {
  -moz-appearance: textfield;
}
*/
</style>
